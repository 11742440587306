var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-edit" },
    [
      _c(
        "Form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            "label-width": 90,
            "label-position": "left",
          },
        },
        [
          _c("FormItem", { attrs: { label: "登录账号：", prop: "username" } }, [
            _vm._v(_vm._s(_vm.form.username) + " "),
          ]),
          _c("FormItem", { attrs: { label: "用户头像：" } }, [
            _c("div", { staticClass: "pic" }, [
              _c(
                "div",
                {
                  staticClass: "pic-box",
                  style: {
                    border: _vm.form.avatarFile ? "0 !important;" : "auto",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.chooseAvatar.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("input", {
                    ref: "upload1",
                    staticStyle: { display: "none" },
                    attrs: { type: "file", accept: "image/*" },
                    on: { change: _vm.avatarChange },
                  }),
                  !_vm.form.avatarFile
                    ? _c("Icon", {
                        attrs: {
                          type: "ios-add",
                          size: "24",
                          color: "#2988f3",
                        },
                      })
                    : [
                        _c("img", {
                          staticStyle: { height: "100%", width: "100%" },
                          attrs: { src: _vm.form.avatarFile },
                        }),
                        _c(
                          "div",
                          { staticClass: "demo-upload-list-cover" },
                          [
                            _c("Icon", {
                              attrs: { type: "ios-eye-outline" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleView(_vm.form.avatarFile)
                                },
                              },
                            }),
                            _c("Icon", {
                              attrs: { type: "ios-trash-outline" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleRemove(1)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                ],
                2
              ),
            ]),
          ]),
          _c(
            "FormItem",
            {
              attrs: { label: "用户名：", prop: "nickname", error: _vm.error },
            },
            [
              _c("Input", {
                staticStyle: { width: "300px" },
                model: {
                  value: _vm.form.nickname,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "nickname", $$v)
                  },
                  expression: "form.nickname",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "性别：" } },
            [
              _c("dict", {
                staticStyle: { width: "300px" },
                attrs: { dict: "sex" },
                model: {
                  value: _vm.form.sex,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sex", $$v)
                  },
                  expression: "form.sex",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "生日：" } },
            [
              _c("DatePicker", {
                staticStyle: { width: "300px" },
                attrs: { type: "date" },
                on: { "on-change": _vm.changeBirth },
                model: {
                  value: _vm.form.birth,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "birth", $$v)
                  },
                  expression: "form.birth",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "所在省市：" } },
            [
              _c("al-cascader", {
                staticStyle: { width: "300px" },
                attrs: { "data-type": "name", level: "2" },
                model: {
                  value: _vm.form.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "address", $$v)
                  },
                  expression: "form.address",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "街道地址：", prop: "street" } },
            [
              _c("Input", {
                staticStyle: { width: "300px" },
                model: {
                  value: _vm.form.street,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "street", $$v)
                  },
                  expression: "form.street",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "个人简介：", prop: "description" } },
            [
              _c("Input", {
                staticStyle: { width: "300px" },
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 3, maxRows: 5 },
                  placeholder: "个人简介",
                },
                model: {
                  value: _vm.form.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description",
                },
              }),
            ],
            1
          ),
          _c("FormItem", { attrs: { label: "所属部门：" } }, [
            _c("span", [_vm._v(_vm._s(_vm.form.departmentTitle))]),
          ]),
          _c("FormItem", { attrs: { label: "用户类型：" } }, [
            _c("span", [_vm._v(_vm._s(_vm.form.typeTxt))]),
          ]),
          _c("FormItem", { attrs: { label: "签名文件" } }, [
            _c("div", { staticClass: "pic" }, [
              _c(
                "div",
                {
                  staticClass: "pic-box",
                  staticStyle: { width: "100px", height: "60px" },
                  style: {
                    border: _vm.form.autographFile ? "0 !important;" : "auto",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.photograph.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("input", {
                    ref: "upload2",
                    staticStyle: { display: "none" },
                    attrs: { type: "file", accept: "image/*" },
                    on: { change: _vm.autographChange },
                  }),
                  !_vm.form.autographFile
                    ? _c("Icon", {
                        attrs: {
                          type: "ios-add",
                          size: "24",
                          color: "#2988f3",
                        },
                      })
                    : [
                        _c("img", {
                          staticStyle: { height: "100%", width: "100%" },
                          attrs: { src: _vm.form.autographFile },
                        }),
                        _c(
                          "div",
                          { staticClass: "demo-upload-list-cover" },
                          [
                            _c("Icon", {
                              attrs: { type: "ios-eye-outline" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleView(_vm.form.autographFile)
                                },
                              },
                            }),
                            _c("Icon", {
                              attrs: { type: "ios-trash-outline" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleRemove(2)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                ],
                2
              ),
            ]),
          ]),
          _c(
            "FormItem",
            [
              _c(
                "Button",
                {
                  staticStyle: { width: "100px", "margin-right": "5px" },
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.saveEdit },
                },
                [_vm._v("保存 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: { title: "图片剪裁", transfer: "", "mask-closable": false },
          model: {
            value: _vm.cropperDialogVisible,
            callback: function ($$v) {
              _vm.cropperDialogVisible = $$v
            },
            expression: "cropperDialogVisible",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                height: "400px",
                width: "auto",
                "text-align": "center",
              },
            },
            [
              _c("VueCropper", {
                ref: "cropper",
                attrs: {
                  img: _vm.option.img,
                  outputSize: _vm.option.size,
                  outputType: _vm.option.outputType,
                  info: true,
                  autoCropWidth: _vm.option.autoCropWidth,
                  autoCropHeight: _vm.option.autoCropHeight,
                  full: _vm.option.full,
                  canMove: _vm.option.canMove,
                  canMoveBox: _vm.option.canMoveBox,
                  original: _vm.option.original,
                  autoCrop: _vm.option.autoCrop,
                  fixed: _vm.option.fixed,
                  fixedNumber: _vm.option.fixedNumber,
                  centerBox: _vm.option.centerBox,
                  infoTrue: _vm.option.infoTrue,
                  fixedBox: _vm.option.fixedBox,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "Button",
                { attrs: { type: "primary" }, on: { click: _vm.finish } },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }